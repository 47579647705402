import React, { ReactElement } from 'react';
import { ValidationError } from '../context/photoConstants';
import warningAlert from '../assets/imgs/warning-alert.png';

import './ValidationWarning.scss';
import i18n from '../utils/i18n';

interface WarningProps {
  title: string;
  message: string;
  handleClick?: () => void;
  hideButton?: boolean;
  note?: string;
}

const Warning = ({
  title,
  message,
  hideButton,
  note,
  handleClick = (): null => null,
}: WarningProps): ReactElement => {
  return (
    <div id="warning-msg-container" className="warning">
      <div className="warning__container">
        <img
          src={warningAlert}
          className="warning--alert"
          alt="Warning Alert"
        />
        <h2>{title}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></p>
        <p>{note}</p>
        {!hideButton && (
          <button className="button" onClick={handleClick}>
            Tomar nuevamente
          </button>
        )}
      </div>
    </div>
  );
};

export default function ValidationWarning({
  validationError,
  handleWarningButton,
}: {
  validationError: any;
  handleWarningButton: any;
}): ReactElement {
  return (
    <>
      {validationError === ValidationError.NO_DOCUMENT && (
        <Warning
          title="Documento no detectado"
          message="Parece que la foto que tomaste no corresponde a un documento o se encuentra borrosa"
          handleClick={handleWarningButton}
        />
      )}
      {validationError === ValidationError.NO_VEHICLE && (
        <Warning
          title="Vehículo no detectado"
          message="Parece que la foto que tomaste no corresponde a un vehículo o se encuentra borrosa"
          handleClick={handleWarningButton}
        />
      )}
      {validationError === ValidationError.NO_PLATE && (
        <Warning
          title="Placa no detectada"
          message="Parece que la foto que tomaste no corresponde a una placa o se encuentra borrosa"
          handleClick={handleWarningButton}
        />
      )}
      {validationError === ValidationError.NO_PLATE_MATCH && (
        <Warning
          title="Foto de placa incorrecta"
          message="La foto de la placa no corresponde a la placa de la solicitud, favor verificar"
          handleClick={handleWarningButton}
        />
      )}
      {validationError === ValidationError.NO_VIN && (
        <Warning
          title="VIN no detectada"
          message="Parece que la foto que tomaste no corresponde a un VIN o se encuentra borrosa"
          handleClick={handleWarningButton}
        />
      )}
      {validationError === ValidationError.IS_BLURRY && (
        <Warning
          title="Foto borrosa"
          message={i18n.get(
            'gallery.blurryMessage',
            'Parece que la foto que tomaste se encuentra borrosa.',
          )}
          handleClick={handleWarningButton}
        />
      )}
      {validationError === ValidationError.NO_ODOMETER && (
        <Warning
          title="Kilometraje no detectado"
          message="Parece que la foto que tomaste no corresponde a un tacómetro de un vehículo o se encuentra borrosa"
          handleClick={handleWarningButton}
        />
      )}
    </>
  );
}
