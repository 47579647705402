export const getOS = (): string => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf('Win') !== -1) {
    return 'Windows';
  }
  if (userAgent.indexOf('Mac') !== -1) {
    return 'Mac';
  }
  if (userAgent.indexOf('Linux') !== -1) {
    return 'Linux';
  }
  if (userAgent.indexOf('Android') !== -1) {
    return 'Android';
  }
  if (userAgent.indexOf('like Mac') !== -1) {
    return 'iOS';
  }

  return 'Unknown';
};
