const PENDO_APIKEY = process.env.REACT_APP_PENDO_APIKEY;
if (!PENDO_APIKEY) {
  throw new Error(
    'REACT_APP_PENDO_APIKEY no está definida en las variables de entorno',
  );
}

export interface PendoUserData {
  Email: string;
  PhoneNumber: string;
}

const COUNTRY_BRANCHES: Record<string, string> = {
  PAN: 'Panama',
  PRI: 'Puerto Rico',
  CRC: 'Costa Rica',
  COL: 'Colombia',
  MEX: 'Mexico',
} as const;

const ACCOUNT_REFERENCE: Record<string, { id: string; name: string }> = {
  is: { id: '7', name: 'Internacional de Seguros' },
  sura: { id: '15', name: 'SURA' },
  bcr: { id: '33', name: 'BCR Corredora de Seguros' },
  ins: { id: '42', name: 'INS Instituto Nacional de Seguros' },
} as const;

const validateCountryCode = (code: string): boolean => {
  return Boolean(COUNTRY_BRANCHES[String(code).toUpperCase()]);
};

const validateBrand = (brand: string): boolean => {
  return Boolean(ACCOUNT_REFERENCE[String(brand)].id);
};

export const loadPendoScript = async (): Promise<void> => {
  return await new Promise((resolve, reject) => {
    const cleanup = () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };

    if (window.pendo?.isReady()) {
      resolve();

      return;
    }

    const script = document.createElement('script');
    script.src = `https://cdn.pendo.io/agent/static/${PENDO_APIKEY}/pendo.js`;
    script.async = true;

    script.onload = () => {
      console.log('Pendo script loaded and initialized.');
      resolve();
    };

    script.onerror = () => {
      cleanup();

      // eslint-disable-next-line no-console
      console.error('Failed to load the Pendo script.');
      reject(new Error('Failed to load the Pendo script'));
    };

    window.addEventListener('unload', cleanup);
    document.body.appendChild(script);
  });
};

export const clearPendoData = (): void => {
  try {
    if (!window.pendo) {
      throw new Error('Objeto pendo no disponible');
    }
    window.pendo.clearSession();
    window.pendo.initialize({
      apiKey: String(PENDO_APIKEY),
      visitor: {
        id: '',
      },
      account: {
        id: '',
      },
    });
    console.log('Datos de Pendo limpiados correctamente.');
  } catch (error) {
    console.error('Error al limpiar datos de Pendo:', error);
  }
};

interface PendoConfig {
  visitor: {
    id: string;
    email: string;
    phone: string;
    branch: string;
  };
  account: {
    id: string;
    account: string;
    branch: string;
  };
}

const initializePendoWithTimeout = async (
  config: PendoConfig,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    let isResolved = false;

    const timeoutId = setTimeout(() => {
      clearInterval(checkInterval);
      isResolved = true;
      reject(new Error('Timeout esperando que Pendo esté listo'));
    }, 5000);

    const checkInterval = setInterval(() => {
      if (!isResolved && typeof window.pendo?.isReady === 'function') {
        clearInterval(checkInterval);
        clearTimeout(timeoutId);
        isResolved = true;

        try {
          if (window.pendo.isReady()) {
            window.pendo.updateOptions(config);
            console.log('Pendo actualizado correctamente');
          } else {
            window.pendo.initialize({
              apiKey: String(PENDO_APIKEY),
              ...config,
            });
            console.log('Pendo inicializado correctamente');
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    }, 100);
  });
};

export const initializeOrUpdatePendo = async (
  email: string,
  phone: string,
): Promise<void> => {
  try {
    const countryCode = process.env.REACT_APP_COUNTRY_CODE || '';
    if (!validateCountryCode(countryCode)) {
      throw new Error(`Código de país no válido: ${countryCode}`);
    }

    const branch = COUNTRY_BRANCHES[String(countryCode).toUpperCase()] || '';

    const accountReference =
      ACCOUNT_REFERENCE[String(process.env.REACT_APP_BRAND)].id;
    if (!validateBrand(process.env.REACT_APP_BRAND || '')) {
      throw new Error(
        `Referencia de socio no válida: ${process.env.REACT_APP_BRAND}`,
      );
    }
    const accountName =
      ACCOUNT_REFERENCE[String(process.env.REACT_APP_BRAND)].name || '';

    if (!window.pendo) {
      throw new Error('Objeto pendo no disponible');
    }

    const config = {
      visitor: {
        id: email,
        email,
        phone,
        branch,
      },
      account: {
        id: String(accountReference),
        account: accountName,
        branch,
      },
    };

    await initializePendoWithTimeout(config);
  } catch (error) {
    console.error('Error al inicializar Pendo:', error);
    throw error;
  }
};
