import React from 'react';

import {
  PhotoId as IVPhotoId,
  getTooltipContents,
} from '@connect-technology/connect-react-components-lib';
import { MediaId } from '../context/photoConstants';
import { DispatchFunction } from '../types';
import { deletePhotoFromServer } from './photoUploader';
import { ActionType } from '../context/actions';
import { Company } from '../interfaces/brand';
import { BRAND } from '../static/constants/brands/brand';

// TODO: Import from connect-react-components-lib.
interface TooltipContent {
  title: string;
  imgUrl?: string;
  paragraph: React.ReactNode;
}

/**
 * The idea of this function is allow us to both re-use the original
 * getTooltipContents from IV, and to extend it with new Photos
 * (like VEHICLE_TOP).
 */
export function getHelpModalContents(
  mediaId: MediaId | null,
): TooltipContent | null {
  if (mediaId === null) {
    return null;
  }

  const ivPhotoId = transformPhotoId(mediaId);
  if (ivPhotoId === null) {
    return null;
  }

  const toolTip = getCustomTooltipContent(ivPhotoId);

  if(!toolTip) {
    return getTooltipContents(ivPhotoId);
  }

  return toolTip;
}

const getCustomTooltipContent = (id: IVPhotoId): TooltipContent | null => {
  if (
		[IVPhotoId.INSURED_VEHICLE_FRONT, IVPhotoId.INJURED_VEHICLE_FRONT].includes(id)
	) {
		return {
			title: 'Zona Frontal del Vehículo',
			imgUrl: 'https://connect-assistant-public-assets.s3.us-east-1.amazonaws.com/preinspect/vh-front-0-fix.jpg',
			paragraph: (
				<p>
					Captura la zona <b>frontal</b> del vehículo. 
          <ul>
            <li>Se debe mostrar la zona completa del vehículo y sin cortes <b>(ver imagen)</b>.</li>
            <li>La placa debe ser visible y legible.</li>
            <li>Toma la foto de frente (no hacer capturas diagonales).</li>
            <li>La foto debe tener luz adecuada.</li>
            <li>Si la foto no cumple con estos requisitos, tendrás que repetirla.</li>
          </ul>
				</p>
			),
		};
	} else if (
		[
			IVPhotoId.INSURED_VEHICLE_PASSENGER_SIDE,
			IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE,
		].includes(id)
	) {
		return {
			title: 'Zona del Pasajero',
			imgUrl: 'https://connect-assistant-public-assets.s3.us-east-1.amazonaws.com/preinspect/vh-right-0-fix.jpg',
			paragraph: (
				<p>
					Captura el lado <b>derecho (zona pasajero)</b> del vehículo. 
          <ul>
            <li>Se debe mostrar la zona completa del vehículo y sin cortes <b>(ver imagen)</b>.</li>
            <li>Toma la foto de frente (no hacer capturas diagonales).</li>
            <li>La foto debe tener luz adecuada.</li>
            <li>Si la foto no cumple con estos requisitos, tendrás que repetirla.</li>
          </ul>
				</p>
			),
		};
	} else if (
		[
			IVPhotoId.INSURED_VEHICLE_BACK,
			IVPhotoId.INJURED_VEHICLE_BACK,
			IVPhotoId.COUNTERPART_VEHICLE_BACK,
		].includes(id)
	) {
		return {
			title: 'Zona Trasera del Vehículo',
			imgUrl: 'https://connect-assistant-public-assets.s3.us-east-1.amazonaws.com/preinspect/vh-back-0-fix.jpg',
			paragraph: (
        <p>
					Captura la zona <b>trasera</b> del vehículo. 
          <ul>
            <li>Se debe mostrar la zona completa del vehículo y sin cortes <b>(ver imagen)</b>.</li>
            <li>La placa debe ser visible y legible.</li>
            <li>Toma la foto de frente (no hacer capturas diagonales).</li>
            <li>La foto debe tener luz adecuada.</li>
            <li>Si la foto no cumple con estos requisitos, tendrás que repetirla.</li>
          </ul>
				</p>				
			),
		};
	} else if (
		[
			IVPhotoId.INSURED_VEHICLE_DRIVER_SIDE,
			IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE,
		].includes(id)
	) {
		return {
			title: 'Zona del Conductor',
			imgUrl: 'https://connect-assistant-public-assets.s3.us-east-1.amazonaws.com/preinspect/vh-left-0-fix.jpg',
			paragraph: (
				<p>
					Captura el lado <b>izquierdo (zona conductor)</b> del vehículo. 
          <ul>
            <li>Se debe mostrar la zona completa del vehículo y sin cortes <b>(ver imagen)</b>.</li>
            <li>Toma la foto de frente (no hacer capturas diagonales).</li>
            <li>La foto debe tener luz adecuada.</li>
            <li>Si la foto no cumple con estos requisitos, tendrás que repetirla.</li>
          </ul>
				</p>
			),
		};
	}

  return null;
}

//
export function transformPhotoId(mediaId: MediaId): IVPhotoId | null {
  switch (mediaId) {
    // Vehicle Exterior
    case MediaId.VEHICLE_EXTERIOR_BACK:
      return IVPhotoId.INJURED_VEHICLE_BACK;
    case MediaId.VEHICLE_EXTERIOR_FRONT:
      return IVPhotoId.INJURED_VEHICLE_FRONT;
    case MediaId.VEHICLE_EXTERIOR_LEFT:
      return IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE;
    case MediaId.VEHICLE_EXTERIOR_RIGHT:
      return IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE;
    case MediaId.VEHICLE_EXTERIOR_PLATE:
      return IVPhotoId.INJURED_VEHICLE_PLATE_PA;
    case MediaId.VEHICLE_EXTERIOR_PLATE_CR:
      return IVPhotoId.INSURED_VEHICLE_PLATE_CR;
    case MediaId.VEHICLE_EXTERIOR_LEFT_FRONT:
      return IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE_FRONT;
    case MediaId.VEHICLE_EXTERIOR_LEFT_BACK:
      return IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE_BACK;
    case MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT:
      return IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE_FRONT;
    case MediaId.VEHICLE_EXTERIOR_RIGHT_BACK:
      return IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE_BACK;
    // Vehicle Interior
    case MediaId.VEHICLE_INTERIOR_VIN:
      return IVPhotoId.INJURED_VEHICLE_VIN;
    case MediaId.VEHICLE_INTERIOR_MOTOR:
      return IVPhotoId.INJURED_VEHICLE_MOTOR;
    case MediaId.VEHICLE_INTERIOR_1:
      return IVPhotoId.INJURED_VEHICLE_INTERIOR_1;
    case MediaId.VEHICLE_INTERIOR_2:
      return IVPhotoId.INJURED_VEHICLE_INTERIOR_2;
    case MediaId.VEHICLE_INTERIOR_ODOMETER:
      return IVPhotoId.INJURED_VEHICLE_ODOMETER;
    // Documents
    case MediaId.DOCUMENTS_CEDULA:
      return IVPhotoId.COUNTERPART_CEDULA;
    case MediaId.COUNTERPART_CEDULA:
      return IVPhotoId.COUNTERPART_CEDULA;
    case MediaId.DOCUMENTS_DRIVERS_LICENSE:
      return IVPhotoId.COUNTERPART_LICENSE_PA;
    case MediaId.VEHICLE_INTERIOR_MOTOR_PANO:
      return IVPhotoId.VEHICLE_INTERIOR_MOTOR_PANO;
    case MediaId.DOCUMENTS_DRIVERS_PORTRAIT:
      return IVPhotoId.PORTRAIT;
    case MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY:
      return IVPhotoId.COUNTERPART_REGISTRO_UNICO;
    case MediaId.DEKRA:
      return IVPhotoId.DEKRA;
    case MediaId.TRUNK:
      return IVPhotoId.TRUNK;
    case MediaId.DOCUMENTS_COUNTERPART_CEDULA_CR:
      return IVPhotoId.COUNTERPART_CEDULA_CR;
    case MediaId.DOCUMENTS_BACK_CEDULA_CR:
      return IVPhotoId.BACK_CEDULA_CR;
    default:
      return null;
  }
}

export const removePhotoFromPreview = async (
  id: MediaId,
  dispatch: DispatchFunction,
  url?: string,
): Promise<void> => {
  if (url) {
    await deletePhotoFromServer(url);
    dispatch({
      type: ActionType.REMOVE_PHOTO,
      payload: { id: id },
    });
  }
};

export const refreshGalleryOneScreen = (dispatch: DispatchFunction): void => {
  if ([Company.SURA.toString()].includes(BRAND)) {
    dispatch({
      type: ActionType.SET_REFRESH_GALLERY_ONE_SCREEN,
      payload: true,
    });
  }
};
