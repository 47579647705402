import Axios, { AxiosStatic } from 'axios';
import { IExteriorData, IVehicleInsightsResult } from '../types';

const HEIMDALL_API = process.env.REACT_APP_HEIMDALL_API;
const MS_VEHICLE_HUB_API = process.env.REACT_APP_MS_VEHICLE_HUB_API;

export default class HeimdallClient {
  constructor(private axios: AxiosStatic = Axios) {}

  async isDocument(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/document-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isVehicle(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/vehicle-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isIdOrLicense(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/id-license-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isBlurry(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/is-blurry?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isOdometer(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/odometer-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isVehicleInternal(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/veh-internal-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async extractPlate(s3Url: string): Promise<string> {
    if (MS_VEHICLE_HUB_API != null) {
      try {
        const response: any = await this.axios.post(
          `${MS_VEHICLE_HUB_API}/plateValidation`,
          {
            url: s3Url,
          },
        );

        return response.data?.success ? response.data?.data : '';
      } catch (error) {
        console.warn(error);

        return '';
      }
    } else {
      const response = await this.axios.get(
        `${HEIMDALL_API}/plate-extraction?s3Url=${s3Url}`,
      );

      return response.data;
    }
  }

  async extractVin(s3Url: string): Promise<string> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/vin-extraction?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async extractRUV(s3Url: string): Promise<string> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/ruv-extraction?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async hasLegibleLicense(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/is-legible?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async predictVehicle(objData: IExteriorData): Promise<void> {
    await this.axios.post(`${HEIMDALL_API}/predict-vehicle`, objData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async vehicleZoneExtraction(objData: IExteriorData): Promise<string> {
    if (MS_VEHICLE_HUB_API) {
      const responseValue = {
        isVehicle: false,
        quality: false,
        side: 'NONE',
        description: 'Error in analysis',
      } as IVehicleInsightsResult;

      const response: any = await this.axios.post(
        `${MS_VEHICLE_HUB_API}/insightAnalyzer`,
        {
          url: objData.s3Url,
        },
      );

      return response.data?.success
        ? (response.data?.data as IVehicleInsightsResult).side
        : responseValue.side;
    } else {
      const response = await this.axios.post(
        `${HEIMDALL_API}/zone-extraction`,
        {
          s3Url: objData.s3Url,
          detectZone: objData.detectZone,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data?.sideName;
    }
  }

  async extractVehicleInsights(s3Url: string): Promise<IVehicleInsightsResult> {
    const responseValue = {
      isVehicle: false,
      quality: false,
      side: 'NONE',
      description: 'Error in analysis',
    } as IVehicleInsightsResult;

    try {
      const response: any = await this.axios.post(
        `${MS_VEHICLE_HUB_API}/insightAnalyzer`,
        {
          url: s3Url,
        },
      );

      return response.data?.success
        ? (response.data?.data as IVehicleInsightsResult)
        : responseValue;
    } catch (error) {
      console.warn(error);

      return responseValue;
    }
  }
}
