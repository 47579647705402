import { IPreInspectionModel } from '../../types';

export const preInspectDefault: IPreInspectionModel = {
  preInspectionId: 0,
  preCompanyId: 0,
  preInsuranceType: '',
  preIdType: '',
  preIdInsured: '',
  preName: '',
  preLastName1: '',
  preLastName2: '',
  preBirthDate: '',
  prePhoneNumber: '',
  preEmail: '',
  preProvince: '',
  preCanton: '',
  preDistrict: '',
  preBrokerCode: '',
  preBrokerName: '',
  preCreationDate: '',
  preVehicleBrand: '',
  preVehicleModel: '',
  preVehicleYear: 0,
  preVehicleType: '',
  preVehicleVin: '',
  preVehicleMotor: '',
  preVehicleColor: '',
  prePaxNumber: 0,
  preTransmission: '',
  preCylinderCapacity: 0,
  preInsuranceAmount: 0,
  preInitialValidity: '',
  preFinalValidity: '',
  prePlanType: '',
  preCoveragePlan: '',
  preBenefits: '',
  preQuoteNumber: '',
  preVehiclePlate: '',
  preSignature: '',
  preTermsAcceptance: false,
  preRedirectURL: '',
  preIsInactive: false,
  preEmisionType: 0,
  preIsBroker: false,
  preActiveQuestionBroker: false,
};

export const extractedDataDefault = {
  plate: null,
  vin: null,
};
