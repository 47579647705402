import { BrandInfo } from '../../../interfaces/brand';

export const brandingInfo: { [key: string]: BrandInfo } = {
  is: {
    welcomeMsg:
      'Toma en cuenta que el proceso de inspección debe ser realizado en su totalidad por el Asegurado y/o el Corredor de Seguros.',
    pages: 4,
    vinMsg:
      'Tener previamente identificada la ubicación del número de VIN y número de motor del vehículo.',
    keyDocumentPage: '1',
    keyTimePage: '2',
    keyPhotosPage: '3',
    keyLocationPage: '4',
    pagesToRemove: [],
    galleryDocument: [
      'DOCUMENTS_VEHICLE_PROPERTY_REGISTRY',
      'DOCUMENTS_CEDULA',
      'DOCUMENTS_DRIVERS_LICENSE',
      'DOCUMENTS_DRIVERS_PORTRAIT',
    ],
    galleryExterior: [
      'VEHICLE_EXTERIOR_PLATE',
      'VEHICLE_EXTERIOR_FRONT',
      'VEHICLE_EXTERIOR_RIGHT',
      'VEHICLE_EXTERIOR_RIGHT_FRONT',
      'VEHICLE_EXTERIOR_RIGHT_BACK',
      'VEHICLE_EXTERIOR_BACK',
      'VEHICLE_EXTERIOR_LEFT',
      'VEHICLE_EXTERIOR_LEFT_FRONT',
      'VEHICLE_EXTERIOR_LEFT_BACK',
    ],
    galleryInterior: [
      'VEHICLE_INTERIOR_ODOMETER',
      'VEHICLE_INTERIOR_VIN',
      'VEHICLE_INTERIOR_MOTOR',
      'VEHICLE_INTERIOR_1',
      'VEHICLE_INTERIOR_2',
      'VEHICLE_INTERIOR_MOTOR_PANO',
    ],
    galleryOthers: [],
    videoVisible: true,
  },
  connect: {
    welcomeMsg:
      'Toma en cuenta que el proceso de inspección debe ser realizado en su totalidad por el Asegurado y/o el Corredor de Seguros.',
    pages: 4,
    vinMsg:
      'Tener previamente identificada la ubicación del número de VIN y número de motor del vehículo.',
    keyDocumentPage: '1',
    keyTimePage: '2',
    keyPhotosPage: '3',
    keyLocationPage: '4',
    pagesToRemove: [],
    galleryDocument: [
      'DOCUMENTS_VEHICLE_PROPERTY_REGISTRY',
      'DOCUMENTS_CEDULA',
      'DOCUMENTS_DRIVERS_LICENSE',
      'DOCUMENTS_DRIVERS_PORTRAIT',
    ],
    galleryExterior: [
      'VEHICLE_EXTERIOR_PLATE',
      'VEHICLE_EXTERIOR_FRONT',
      'VEHICLE_EXTERIOR_RIGHT',
      'VEHICLE_EXTERIOR_RIGHT_FRONT',
      'VEHICLE_EXTERIOR_RIGHT_BACK',
      'VEHICLE_EXTERIOR_BACK',
      'VEHICLE_EXTERIOR_LEFT',
      'VEHICLE_EXTERIOR_LEFT_FRONT',
      'VEHICLE_EXTERIOR_LEFT_BACK',
    ],
    galleryInterior: [
      'VEHICLE_INTERIOR_ODOMETER',
      'VEHICLE_INTERIOR_VIN',
      'VEHICLE_INTERIOR_MOTOR',
      'VEHICLE_INTERIOR_1',
      'VEHICLE_INTERIOR_2',
      'VEHICLE_INTERIOR_MOTOR_PANO',
    ],
    galleryOthers: [],
    videoVisible: true,
  },
  sura: {
    welcomeMsg:
      'Toma en cuenta que el proceso de inspección debe ser realizado en su totalidad por el Asegurado y/o el Corredor de Seguros.',
    footerMsg:
      'Regulado y supervisado por la Superintendencia de Seguros y Reaseguros de Panamá',
    pages: 2,
    vinMsg:
      'Tener previamente identificada la ubicación del número de VIN y número de motor del vehículo.',
    keyDocumentPage: '0',
    keyTimePage: '1',
    keyPhotosPage: '2',
    keyLocationPage: '0',
    pagesToRemove: [],
    galleryDocument: [],
    galleryExterior: [
      'VEHICLE_EXTERIOR_FRONT',
      'VEHICLE_EXTERIOR_RIGHT',
      'VEHICLE_EXTERIOR_BACK',
      'VEHICLE_EXTERIOR_LEFT',
    ],
    galleryInterior: ['VEHICLE_INTERIOR_VIN', 'VEHICLE_INTERIOR_1'],
    galleryOthers: [],
    videoVisible: false,
  },
  bcr: {
    welcomeMsg:
      'Tome en cuenta que el proceso de inspección debe ser realizado en su totalidad por el Asegurado.',
    pages: 3,
    vinMsg: 'Tener previamente identificada la ubicación del número de VIN.',
    keyDocumentPage: '0',
    keyTimePage: '1',
    keyPhotosPage: '2',
    keyLocationPage: '3',
    pagesToRemove: [0],
    galleryDocument: [
      'DOCUMENTS_COUNTERPART_CEDULA_CR',
      'DOCUMENTS_BACK_CEDULA_CR',
      'DEKRA',
    ],
    galleryExterior: [
      'VEHICLE_EXTERIOR_FRONT',
      'VEHICLE_EXTERIOR_RIGHT',
      'VEHICLE_EXTERIOR_BACK',
      'VEHICLE_EXTERIOR_LEFT',
    ],
    galleryInterior: [
      'VEHICLE_INTERIOR_ODOMETER',
      'VEHICLE_INTERIOR_VIN',
      'VEHICLE_INTERIOR_1',
      'VEHICLE_INTERIOR_2',
      'VEHICLE_INTERIOR_MOTOR_PANO',
      'TRUNK',
    ],
    galleryOthers: [],
    videoVisible: false,
  },
  ins: {
    welcomeMsg:
      'Tome en cuenta que el proceso de inspección debe ser realizado en su totalidad por el Asegurado.',
    pages: 0,
    vinMsg: 'Tener previamente identificada la ubicación del número de VIN.',
    keyDocumentPage: '0',
    keyTimePage: '0',
    keyPhotosPage: '2',
    keyLocationPage: '0',
    pagesToRemove: [0],
    galleryDocument: [
      'DOCUMENTS_COUNTERPART_CEDULA_CR',
      'DOCUMENTS_BACK_CEDULA_CR',
      'DEKRA',
    ],
    galleryExterior: [
      'VEHICLE_EXTERIOR_PLATE_CR',
      'VEHICLE_EXTERIOR_FRONT',
      'VEHICLE_EXTERIOR_RIGHT',
      'VEHICLE_EXTERIOR_BACK',
      'VEHICLE_EXTERIOR_LEFT',
    ],
    galleryInterior: [
      'VEHICLE_INTERIOR_ODOMETER',
      'VEHICLE_INTERIOR_VIN',
      'VEHICLE_INTERIOR_1',
      'VEHICLE_INTERIOR_2',
      'VEHICLE_INTERIOR_MOTOR_PANO',
      'TRUNK',
    ],
    galleryOthers: ['VEHICLE_INTERIOR_1', 'VEHICLE_INTERIOR_VIN', 'DEKRA'],
    videoVisible: false,
  },
};
