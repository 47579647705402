import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import reportWebVitals from './reportWebVitals';
import Router from './Router';
import './index.scss';
import '@connect-technology/connect-react-components-lib/dist/index.css';
import i18n from './utils/i18n';
import { BRAND } from './static/constants/brands/brand';
import { loadPendoScript, initializeOrUpdatePendo } from './pendo/pendoUtils';

Sentry.init({
  dsn: 'https://653fd9f143704b87ac8bb23a0c70149e@o298640.ingest.sentry.io/6553912',
  environment: process.env.NODE_ENV,
  debug: process.env.NODE_ENV === 'development',
  // Per Sentry's recommendation:
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.0,

  // Filter out large breadcrumbs (otherwise Sentry yields 413 Too Much Data error)
  beforeSend(event) {
    if (event.breadcrumbs) {
      event.breadcrumbs = event.breadcrumbs.filter((crumb) => {
        return !(crumb.category === 'fetch' && crumb?.data?.url.length > 255);
      });
    }

    return event;
  },
});

(async () => {
  try {
    await loadPendoScript();
    await initializeOrUpdatePendo('', '');
  } catch (error) {
    console.error('Error al cargar Pendo:', error);
    Sentry.captureException(error);
  }

  const state = localStorage.getItem('appState');
  if (state) {
    await i18n.init(BRAND); // Reload after refresh page
  } else if (BRAND && String(BRAND).length > 0) {
    await i18n.init(BRAND);
  }

  ReactDOM.render(
    <React.StrictMode>
      <Router />
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
