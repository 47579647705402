export interface BrandInfo {
  welcomeMsg: string;
  footerMsg?: string;
  pages: number;
  vinMsg: string;
  keyDocumentPage: string;
  keyTimePage: string;
  keyPhotosPage: string;
  keyLocationPage: string;
  pagesToRemove: number[];
  galleryDocument: any[];
  galleryExterior: any[];
  galleryInterior: any[];
  galleryOthers: any[];
  videoVisible: boolean;
}

export enum Company {
  BCR = 'bcr',
  IS = 'is',
  CONNECT = 'connect',
  SURA = 'sura',
  INS = 'ins',
}
